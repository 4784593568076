@mixin only-legacy-edge {
  @supports (-ms-ime-align: auto) { @content; }
}

@mixin only-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { @content; }
  @media screen and (min-width:0\0) and (min-resolution:.001dpcm) { @content; }
}

#browserWarning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  text-align: center;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.5;
  padding: 4%;
  overflow-y: auto;
  display: block !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#browserWarning_Logo {
  margin-top: auto;
}

#browserWarning_Content {
  margin-top: 1em;
  margin-bottom: auto;
}

.body-wrapper {
  display: none !important;
}
.opaque {
  opacity: 1;
}

.desktop-dropdown {
  display: none;
}

@include only-legacy-edge {
  select::-ms-expand {
    display: none;
  }
}

@include only-ie {
  select::-ms-expand {
    display: none;
  }
}

.clearfix {
  clear:both;

  &::before {
    content: "";
    display: table;
    clear: both;
  }
}
